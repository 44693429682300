@import '../../../../scss/theme-bootstrap';

.product-smart-gift-overlay {
  margin-bottom: 20px;
  &__header-container {
    &--header {
      font-size: 30px;
      text-transform: uppercase;
      padding: 25px;
      margin-top: 0;
      margin-bottom: 25px;
      color: $color--white;
      background-color: $color--black;
    }
    &--sub-header {
      text-align: center;
      margin: 30px auto 20px;
      font-style: italic;
      @include breakpoint($bp--medium-up) {
        margin-top: 40px;
        width: 70%;
      }
    }
  }
  &__section {
    margin-top: 20px;
    padding: 0 20px;
    &--header {
      text-transform: uppercase;
    }
  }
  &__colorbox {
    &#colorbox {
      z-index: 9999999;
      background-color: $color--white;
    }
    #cboxLoadedContent {
      padding: 0;
    }
    #cboxClose {
      color: $color--white;
      font-size: 14px;
      padding: 10px;
    }
  }
}
